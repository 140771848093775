<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 피심사부서 -->
          <c-dept 
            type="search" 
            label="LBL0010133" 
            name="deptCd" 
            :isFirstValue="false"
            v-model="searchParam.deptCd" />
        </div>
      </template>
    </c-search-box>
    <!--안전보건 내부심사 결과서 목록-->
    <c-table
      ref="table"
      title=""
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-result',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: '',
        isResult: true,
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'saiInternalActionId' },
          { index: 1, colName: 'saiInternalActionId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionName',
            field: 'actionName',
            //수행계획서명
            label: 'LBL0010116',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            //피심사팀
            label: 'LBL0010111',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'link'
          },
          {
            name: 'saiInternalActionTargetDeptStepName',
            field: 'saiInternalActionTargetDeptStepName',
            //진행상태
            label: 'LBLPROGRESS',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.internal.targetDept.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '';   //내부심사 결과서 상세
      this.popupOptions.param = {
        saiInternalActionTargetDeptId: row ? row.saiInternalActionTargetDeptId : '',
      };
      this.popupOptions.target = () => import(`${'./internalResultDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
